import React, {Component} from "react";
import {FaCaretRight} from "react-icons/lib/fa";

class ErrorToast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exceptionNameMap: {
                'BeanValidationException': 'Error de validación de datos',
                'PropertyConversionException': 'Error de validación de datos',
                'Exception': 'Error inesperado'
            },
            errorMap: {
                'INVALID_NUMERIC_RANGE': 'contiene un rango numérico inválido',
                'INVALID_DATE_RANGE': 'contiene una fecha inválida',
                'INVALID_LEN': 'no cumple con la cantidad de caractéres requeridos',
                'REQUIRED_FIELD': 'es requerido',
                'INVALID_NUMBER': 'no es un número válido',
                'INVALID_DATE': 'no es una fecha válida',
            },
            messages: []
        };
    }

    componentWillMount() {
        if(this.props.errorDetail.exceptionName === 'BeanValidationException' ||
           this.props.errorDetail.exceptionName === 'PropertyConversionException') {
            for(let key in this.props.errorDetail.errors) {
                let e = this.props.errorDetail.errors[key];
                this.state.messages.push(`El campo '${e.field}' ${this.state.errorMap[e.error]}.`)
            }
        }
    }

    render() {

        return (
                <React.Fragment>
                    <div
                            className="font-weight-bold">{this.state.exceptionNameMap[this.props.errorDetail.exceptionName]}</div>
                    {this.state.messages.map(m => (
                            <div className="row">
                                <div className="col-md" key={m} style={{fontSize: 0.8 + 'em'}}>
                                    {this.state.messages.length > 1 &&
                                     <FaCaretRight/>
                                    } {m}
                                </div>
                            </div>
                    ))}
                </React.Fragment>
        )
    }
}


export default ErrorToast;