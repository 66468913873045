import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import './Assessment.css';
import { withUser } from "../../context/UserContext";
import impactivo from '../../images/logoimpactivo.png'

class AssessmentHeader extends Component {

	constructor(props) {
		super(props);
		this.state = {
			assessment: props.assessment,
			readOnly: props.readOnly,
			validationErrors: props.validationErrors,
			person: null
		};
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		if (prevProps.readOnly !== this.props.readOnly)
			this.setState({ readOnly: this.props.readOnly })
		if (prevProps.validationErrors !== this.props.validationErrors)
			this.setState({ validationErrors: this.props.validationErrors })
		if (prevProps.assessment !== this.props.assessment) {
			this.setState({ assessment: this.props.assessment })
		}
	}

	render() {
		const fullName = this.state.assessment && this.state.assessment.personName ? this.state.assessment.personName : "";
		const templateName = this.state.assessment && this.state.assessment.assignable && this.state.assessment.assignable.name ? this.state.assessment.assignable.name : "";
		return (
			<div className="header">
				<div className={"p-3 shadow-sm border-bottom row"}>
					<div className={"col-md-1 col-sm-1 branding"}>
						<img id="logoImpactivo" src={impactivo} style={{ height: 'auto' }}
							alt="Impactivo" />
					</div>
					<div className={"col-md-9 col-sm-9"}>
						<div className="row">
							<div className="col-md-12 person-name">
								{fullName}
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 assessment-name">
								{templateName}
							</div>
						</div>
					</div>


					<div className={"col-md-2 col-sm-2 my-auto"}>

						<div className="row">
							<div className="col-md-4">
								{!this.state.readOnly ?
									<Button onClick={(e) => this.props.handleSubmit(e, true)} variant="contained"
										className="editorButton">
										Submit{this.state.assessment && this.state.assessment.assignmentType === 'ANONYMOUS_TAKEN' && " and New"}
									</Button> : null}
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<Button onClick={this.props.changeQuestionView} variant="contained"
									className="editorButton">
									Question View
								</Button>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4">
								<Button onClick={() => this.props.onClose(false)} variant="contained"
									className="editorButton">
									Exit
								</Button>
							</div>
						</div>

					</div>


				</div>
				{this.state.validationErrors ?
					<div className="row errors">
						<div className="col-md-12">There are some errors in this assessment!</div>
					</div> : null
				}
			</div>
		);
	}

}

export default withUser(AssessmentHeader);