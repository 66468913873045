import React, {Component} from 'react';

//TODO: Implement
class Date extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: props.answers,
            date: (props.answers && props.answers.length > 0) ? props.answers[0].answerDate.substr(0, 10) : null,
            readOnly: props.readOnly
        }
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(JSON.stringify(prevProps.answers) !== JSON.stringify(this.props.answers) && this.props.answers.length > 0) {

            this.setState({
                              answers: this.props.answers,
                              date: (this.props.answers && this.props.answers.length > 0) ? this.props.answers[0].answerDate.substr(0, 10) : null,
                          });
        }
        if(prevProps.readOnly !== this.props.readOnly)
            this.setState({readOnly: this.props.readOnly})
    }

    handleChange(event) {
        if(!this.state.readOnly) {
            let answer = this.state.answers && this.state.answers.length > 0 ? this.state.answers[0] :
                    {
                        questionId: this.props.question.id,
                    }
            answer.answerDate = event.target.value;

            this.setState({
                              answers: [answer]
                          })

            if(this.props.handleNewAnswer)
                this.props.handleNewAnswer([answer])
        }
    }

    render() {
        return (
                <div>
                    {this.state.readOnly ?
                     <div>
                         {this.state.date}
                     </div> :
                     <input
                             className="form-control"
                             type="date"
                             // onChange={this.handleChange}
                             required={this.props.question.required}
                             defaultValue={this.state.date}
                             onBlur={this.handleChange}
                     />}
                </div>
        )
    }
}

export default Date;