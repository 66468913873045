import React from 'react';

export const UserContext = React.createContext({
                                                   user: null,
                                                   setUser: (user) => {
                                                       console.log(`Stub called with user: ${JSON.stringify(user)}`)
                                                   }
                                               });

export function withUser(Component) {
    return function UserComponent(props) {

        return (
                <UserContext.Consumer>
                    {({user, setUser}) => <Component {...props} user={user} setUser={setUser}/>}
                </UserContext.Consumer>
        );
    };
}

