import React, {useState} from 'react';
import {HashRouter, Route, Switch} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import './Questionnaire.css';
import LandingPage from "./components/LandingPage";
import {UserContext} from "./context/UserContext"

function Questionnaire() {
    const [user, updateUser] = useState(null);

    function setUser(user) {
        updateUser(user);
    }

    return (

            <UserContext.Provider value={{user, setUser}}>
                <HashRouter>
                    {/*basename={'/assessment'}>*/}
                    <Switch>
                        <Route exact path="/" component={LandingPage}/>
                        <Route path="/:urlKey" component={LandingPage}/>
                    </Switch>
                </HashRouter>
            </UserContext.Provider>

    );
}

export default Questionnaire;
