import fetch from 'safe-fetch';
import toast from './toast';
import {toast as unwrappedToaster} from 'react-toastify';
import React from 'react';
import ErrorToast from "./ErrorToast";

fetch.cookieName = 'XSRF-TOKEN';
fetch.headerName = 'X-XSRF-TOKEN';


export class Rest {

    static contextPath = 'server';

    static authFetch(user, url, options, history) {
        if(!user)
            throw Error("No user sent with request");


        let newOptions = {
            ...options,
            headers: new Headers({
                                     'Authorization': user.tokenType + user.accessToken,
                                     'Content-Type': 'application/json'

                                 }),
            credentials: "omit"
        };

        if(typeof url !== 'string') {
            throw Error('Do not use fetch with Request object')
        }

        let httpResponse;
        return new Promise((resolve, reject) => {
            //The Promise returned from fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500
            fetch(`${Rest.contextPath}${url}`, newOptions)
                    .then((response) => {
                        httpResponse = response;
                        if(httpResponse.status === 200 && response.headers.get('content-type').indexOf('application/json') > -1)
                            return response.json();
                        else
                            return null;
                    })
                    .then((json) => { //from response.json() Promise
                        if(httpResponse.ok) {
                            resolve(json);
                        }
                        else {
                            reject(json);
                            if(httpResponse.status === 401 || httpResponse.status === 403) {
                                if(history) {
                                    history.push('/login');
                                }
                            }
                            else if(httpResponse.status === 204) {
                                //do nothing 404 was sent on reject
                            }
                            else {
                                if(json !== null)
                                    toast.error(json.message);
                                else
                                    toast.error("Something went wrong while reaching the server")
                            }
                        }
                    })
        });

    }

    static rawFetch(url, options) {
        switch(typeof url) {
            case 'string':
                return fetch(`${Rest.contextPath}${url}`, options);

            case 'object':
                throw Error("Do not use fetch with request object");

            default:
                throw Error("Fetch request not understood");
        }

    }

    static getJson(url, history, options) {
        return new Promise((resolve) => {
            return fetch(`${Rest.contextPath}${url}`, options)
                    .then(response => {
                        if(response.status === 401 || response.status === 403) {
                            if(history) {
                                history.push('/login');
                            }
                            throw UnauthenticatedError(response.statusText);
                        }

                        return response.json();
                    }).then(json => {
                        resolve(json)
                    })
                    .catch(err => {
                        if(err instanceof UnauthenticatedError) {
                            console.warn('Unauthenticated');
                            return;
                        }
                        throw err
                    })
        });
    }

    static fetchJson(url, history, options) {
        if(typeof url !== 'string') {
            throw Error('Do not use fetch with Request object')
        }

        let httpResponse;
        return new Promise((resolve, reject) => {
            //The Promise returned from fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500
            fetch(`${Rest.contextPath}${url}`, options)
                    .then((response) => {
                        httpResponse = response;

                        if(response.headers.get('content-type').indexOf('application/json') === -1) {
                            throw new TypeError('Response body is not JSON');
                        }
                        return response.json();
                    })
                    .then((json) => { //from response.json() Promise
                        if(httpResponse.ok) {
                            toast.success('La operación fue completada exitosamente');
                            resolve(json);
                        }
                        else {
                            reject(json);
                            if(httpResponse.status === 401 || httpResponse.status === 403) {
                                if(history) {
                                    history.push('/login');
                                }
                            }
                            else {
                                unwrappedToaster(<ErrorToast errorDetail={json}/>, {
                                                                                       type: unwrappedToaster.TYPE.ERROR
                                                                                   });
                            }
                        }
                    })
        });
    }

}


function UnauthenticatedError() {
}







