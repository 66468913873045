import React, {Component} from 'react';

class FreeText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: props.answers,
            text: (props.answers && props.answers.length > 0) ? props.answers[0].answerText : "",
            readOnly: props.readOnly
        }
        this.onBlur = this.onBlur.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(JSON.stringify(prevProps.answers) !== JSON.stringify(this.props.answers) && this.props.answers.length > 0) {

            this.setState({
                              answers: this.props.answers,
                              text: (this.props.answers && this.props.answers.length > 0) ? this.props.answers[0].answerText : "",
                              // text: this.props.answers[0].answerText
                          });
        }
        if(prevProps.readOnly !== this.props.readOnly)
            this.setState({readOnly: this.props.readOnly})
    }

    onBlur() {

        if(!this.state.readOnly) {
            let answer = this.state.answers && this.state.answers.length > 0 ? this.state.answers[0] : null;
            if(answer === null)
                answer = {questionId: this.props.question.id};
            answer.answerText = this.state.text;
            this.setState({
                              answers: this.state.text === "" ? [] : [answer]
                          })
            if(this.props.handleNewAnswer)
                this.props.handleNewAnswer(this.state.text === "" ? [] : [answer])
        }
    }

    handleInputChange(event) {
        if(!this.state.readOnly) {
            const target = event.target;
            const value = target.value
            this.setState({
                              text: value
                          });
        }
    }

    render() {
        return (
                <div>
                    {this.state.readOnly ?
                     <div>
                         {this.state.text}
                     </div>
                                         : <input
                             className="form-control"
                             type="text"
                             onBlur={this.onBlur}
                             required={this.props.question.required}
                             defaultValue={this.state.text}
                             onKeyUp={this.handleInputChange}

                     />
                    }
                </div>
        )
    }
}

export default FreeText;